import React from "react";
import { Line } from "react-chartjs-2";

class Prediction extends React.Component {
	constructor(props) {
		super(props);
		this.chartRef = React.createRef();
		this.state = {
			top: 0,
			left: 0,
			showTooltip: false,
			label: null,
			value: null,
			valueOld: null,
		};
	}

	setPositionAndData = (payload) => {
		this.setState({
			...this.state,
			top: payload.top,
			left: payload.left,
			label: payload.label,
			value: payload.value,
			valueOld: payload.valueOld,
			showTooltip: true,
		});
	};
	hide = () => {
		this.setState({
			...this.state,
			showTooltip: false,
		});
	};

	_toolTip = (tooltipModel) => {
		let chart = this.chartRef.current;
		if (!chart) {
			this.hide();
			return;
		}
		// hide the tooltip
		if (tooltipModel.opacity === 0) {
			this.hide();
			return;
		}

		const position = chart.chartInstance.canvas.getBoundingClientRect();

		// set position of tooltip
		let left = position.left + tooltipModel.caretX;
		let top = position.top + tooltipModel.caretY;
		const { chartData } = this.props;

		// set values
		var label;
		let index = tooltipModel.dataPoints[0].index;

		label = chartData.labels[index];
		const value = tooltipModel.dataPoints[0].yLabel;

		this.setPositionAndData({ top, left, label, value });
	};

	_tick = (value, index, values) => {
		const { symbol } = this.props;
		return (symbol ? symbol : "") + " " + value;
	};

	render() {
		const options = {
			legend: {
				display: false,
			},

			maintainAspectRatio: false,
			tooltips: {
				enabled: false,
				mode: "x",
				intersect: false,
				custom: this._toolTip,
			},
			scales: {
				yAxes: [
					{
						ticks: {
							// Include a dollar sign in the ticks
							callback: this._tick,
						},
					},
				],
			},
		};
		const left = this.state.left - 75;
		const top = this.state.top - 70;
		const { chartData, height, width, onClickEdit } = this.props;
		return (
			<>
				<div className="chart" style={{ height: "400px" }}>
					<Line data={chartData} height={height} width={width} options={options} ref={this.chartRef} />
					{this.state.showTooltip ? (
						<div
							className="custom-tooltips"
							style={{
								position: "fixed",
								border: "1px solid rgba(0,0,0,0.2)",
								color: "#fff",
								padding: "10px",
								borderRadius: "10px",
								backgroundColor: "#13131A",
								top,
								left,
							}}
						>
							<div>{this.state.label}</div>
							<div>Value: {this.state.value}</div>
						</div>
					) : null}
				</div>
				<div className="card-footer" style={{ marginTop: "40px" }}>
					<button className="btn btn-primary" onClick={() => onClickEdit()}>
						Edit
					</button>
				</div>
			</>
		);
	}
}

export default Prediction;
