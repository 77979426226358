import React from "react";
import Calculate from "./Calculate";
import Prediction from "./Prediction";

const Calculator = ({
  stateData,
  onChange,
  onChangePhoneNumber,
  onChangeSliderValue,
  onClickCalculate,
  chartData,
  onClickEdit,
  language,
}) => {
  let data = require(`../../languages/siteData_${language}.json`);
  let calculatorData = data.calculate_investment;
  return (
    <div className="row" id="calculator">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="comp-info">
          <label className="comp-title">
            {calculatorData.heading.toUpperCase()}
          </label>
          <h3>{calculatorData.sub_heading.toUpperCase()}</h3>
          <div className="comp-desc">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">{calculatorData.description}</div>
            </div>
          </div>
        </div>
        <div style={{ margin: "20px 0" }}>
          {stateData.edit ? (
            <Calculate
              onChangeSliderValue={(o, v) => onChangeSliderValue(o, v)}
              onChange={(e) => onChange(e)}
              onClickCalculate={() => onClickCalculate()}
              onChangePhoneNumber={(e) => onChangePhoneNumber(e)}
              stateData={stateData}
              data={calculatorData.inputs}
              btn_name={data.investment_btn_text}
            />
          ) : (
            <Prediction
              chartData={chartData}
              height={30}
              width={100}
              onClickEdit={() => onClickEdit()}
            />
          )}
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  );
};

export default Calculator;
