import { common } from "../../constants/actionTypes";
import API from "../../api/api";
import swal from "sweetalert";
import CALC_API from "../../api/calculator";

const nodemailer = require("nodemailer");

export const loaderState = (state) => {
	return { type: common.LOADER, payload: state };
};

export const selectedNav = (nav) => {
	return { type: common.SELECTED_URL, payload: nav };
};

export const changeLanguage = (lang) => {
	return {
		type: common.SELECTED_LANGUAGE,
		payload: lang,
	};
};

export const sendEmail = (data) => (dispatch) => {
	API.post("/WebsiteInformation", data)
		.then((res) => {
			dispatch(loaderState(false));
			swal("", "Saved successfully", "success");
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("", err.message, "error");
		});
};

export const sendFeedBack = (data) => (dispatch) => {
	API.post("/sendFeedback", data)
		.then((res) => {
			dispatch(loaderState(false));
			swal("Thank You!", "We have received your feedback", "success");
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("", err.message, "error");
		});
};

export const calculationEmail = (data) => (dispatch) => {
	//	console.log(data);
	CALC_API.post("/investment_calculations/sendMail", data)
		.then((res) => {
			dispatch(loaderState(false));
			//swal("Thank You!", "We have received your feedback", "success");
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("", err.message, "error");
		});
};
