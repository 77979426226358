import React from "react";
import Card from "../common/Card";
import InvestmentPlan from "../Home/InvestmentPlan";
import GetStarted from "../common/GetStarted";
import _ from "lodash";

const Services = ({ onClickGoto, language }) => {
  let data = require(`../../languages/siteData_${language}.json`);
  let ourServices = data.our_services;
  let services = [];
  _.forEach(ourServices.services, (service, index) =>
    services.push({
      icon: `icon${index + 1}@2x`,
      title: service.heading,
      description: service.description,
    })
  );

  return (
    <>
      <div className="container" id="services">
        <div className="comp-info">
          <label className="comp-title">
            {ourServices.heading.toUpperCase()}
          </label>
          <h3>{ourServices.sub_heading.toUpperCase()}</h3>
          <div className="comp-desc">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">{ourServices.description}</div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "50px" }}>
          {services
            ? services.map((service) => {
                var icon = require(`../../assets/images/${service.icon}.png`);
                return (
                  <div className="col-sm-4" key={service.title}>
                    <Card
                      icon={icon}
                      title={service.title.toUpperCase()}
                      description={service.description}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="container">
        <InvestmentPlan
          onClickGoto={(path) => onClickGoto(path)}
          data={data.home.investment_plans}
          btn_name={data.investment_btn_text}
        />
      </div>
      <GetStarted
        onClickGoto={(path) => onClickGoto(path)}
        data={data.getting_started}
        btn_name={data.investment_btn_text}
      />
    </>
  );
};

export default Services;
