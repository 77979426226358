import React from "react";

const FAQ = () => {
  return (
    <div className="container" id="faq">
      FAQ
    </div>
  );
};

export default FAQ;
