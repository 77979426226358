import React from "react";

const Tutorials = () => {
  return (
    <div className="container" id="tutorials">
      Tutorials
    </div>
  );
};

export default Tutorials;
