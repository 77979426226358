import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import Routes from "../routes";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import Loader from "./common/Loader";
import { Container, Button, Link } from "react-floating-action-button";
import ReactGA from "react-ga";
import ReactPlayer from "react-player/lazy";
ReactGA.initialize("UA-188630538-1");

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderPlayer: false,
      displaySkipBtn: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/") {
      this.setState({ renderPlayer: true });
    }
  }

  onProgress = (time) => {
    if (time.playedSeconds > 5) {
      this.setState({ displaySkipBtn: true });
    }
  };

  render() {
    const { isLoading } = this.props;
    let pointerEvents = isLoading ? "none" : "";
    let opacity = isLoading ? 0.6 : 1;

    trackPage(window.location.pathname + window.location.search);

    return (
      <>
        <BrowserRouter>
          <div style={{ pointerEvents, opacity }}>
            <Header />
            <Loader display={isLoading} />
            <Routes />
            <Container>
              {/* <Link href="#" tooltip="Create note link" icon="fa fa-sticky-note" />
						<Link href="#" tooltip="Add user link" icon="fa fa-user-plus" />

						<Button
							styles={{ backgroundColor: "#25d366", color: "#ffffff" }}
							// tooltip="WhatsApp"
							icon="fab fa-whatsapp fa-2x"
							target="_blank"
							// href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202"
							onClick={() => window.open("https://api.whatsapp.com/send?phone=+447946704593&text=Hello", "_blank")}
						/> */}
              <Button
                styles={{ backgroundColor: "#833AB4", color: "#ffffff" }}
                tooltip="Instagram"
                icon="fab fa-instagram fa-2x"
                target="_blank"
                // href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/deep_reef_investment",
                    "_blank"
                  )
                }
              />
              <Button
                styles={{ backgroundColor: "#25d366", color: "#ffffff" }}
                tooltip="WhatsApp"
                icon="fab fa-whatsapp fa-2x"
                target="_blank"
                // href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=+447946704593&text=Hello",
                    "_blank"
                  )
                }
              />

              <Button
                styles={{ backgroundColor: "#428bca", color: "#ffffff" }}
                icon="fas fa-plus"
                rotate={true}
              />
            </Container>
            <Footer />
          </div>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.common.isLoading,
  };
};

export default connect(mapStateToProps, {})(App);
