import React from "react";
import Overview from "./Overview";
import OurMission from "./OurMission";
import Advice from "./Advice";
import MemberShip from "./MemberShip";
import InvestmentPlan from "./InvestmentPlan";
import GetStarted from "../common/GetStarted";

export default function Home({ onClickGoto, language }) {
  let data = require(`../../languages/siteData_${language}.json`);
  return (
    <div id="home">
      <div className="container">
        <Overview
          onClickGoto={(path) => onClickGoto(path)}
          data={data.home.overview}
          btn_name={data.investment_btn_text}
        />
        <OurMission data={data.home.our_mission} />
        <Advice
          onClickGoto={(path) => onClickGoto(path)}
          data={data.home.investment_advice}
          btn_name={data.investment_btn_text}
        />
      </div>
      <div style={{ backgroundColor: "#1c1c24" }}>
        <div className="container">
          <MemberShip
            onClickGoto={(path) => onClickGoto(path)}
            data={data.home.membership}
            btn_name={data.investment_btn_text}
          />
        </div>
      </div>
      <div className="container">
        <InvestmentPlan
          onClickGoto={(path) => onClickGoto(path)}
          data={data.home.investment_plans}
          btn_name={data.investment_btn_text}
        />
      </div>
      <GetStarted
        onClickGoto={(path) => onClickGoto(path)}
        data={data.getting_started}
        btn_name={data.investment_btn_text}
      />
    </div>
  );
}
