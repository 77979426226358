import React from "react";

const Card = ({ icon, title, description }) => {
  return (
    <div className="col-sm-4" id="mission-card">
      <img
        src={icon}
        width="80px"
        // icon.includes("icon1") ? "95px" : "80px"
        height="80px"
      />
      {/* <i id="mission-card-icon" className="fa fa-user-md" /> */}
      <h3>{title}</h3>
      <p style={{ fontSize: "1em" }}>{description}</p>
    </div>
  );
};

export default Card;
