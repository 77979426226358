import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container" id="policy">
      Privacy Policy
    </div>
  );
};

export default PrivacyPolicy;
