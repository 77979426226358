import { calculator } from "../constants/actionTypes";
import { loaderState, calculationEmail } from "./index";
import CALC_API from "../api/calculator";
import swal from "sweetalert";
import SendmailTransport from "nodemailer/lib/sendmail-transport";

export const saveData = (data) => (dispatch) => {
	//console.log(data);

	let currency = data.currency;
	let userInfo = {
		...data,
		symbol: currency === "USD" ? "$" : currency === "GBP" ? "£" : "€",
	};
	//sendEmail(data);
	dispatch(calculationEmail(data));
	dispatch({ type: calculator.INVESTOR_INFO, payload: userInfo });
	dispatch(
		getResults({
			starting_funds: Number(data.starting_funds),
			length: Number(data.length),
		}),
	);
};

export const getResults = (data) => (dispatch) => {
	data.type = "standard";
	CALC_API.post("/investment_calculations/calculator", data)
		.then((res) => {
			dispatch({ type: calculator.CALCULATOR_RESULT, payload: res.data });
			dispatch(loaderState(false));
		})

		.catch((err) => {
			dispatch(loaderState(false));
			swal("", err.message, "error");
		});
};
