import React from "react";
import TermsAndConditionsComp from "../../components/TermAndConditions";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};
class TermsAndConditions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		trackPage(window.location.pathname + window.location.search);

		return <TermsAndConditionsComp />;
	}
}

export default TermsAndConditions;
