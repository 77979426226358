export const common = {
  LOADER: "LOADER",
  SELECTED_URL: "SELECTED_URL",
  SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
};

export const calculator = {
  INVESTOR_INFO: "INVESTOR_INFO",
  CALCULATOR_RESULT: "CALCULATOR_RESULT",
};
