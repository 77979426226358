import React from "react";
import { connect } from "react-redux";
import { isEmptyOrNull } from "../../helper";
import swal from "sweetalert";
import _ from "lodash";
import moment from "moment";
import { loaderState, saveData } from "../../actions";
import CalculatorComp from "../../components/Calculator";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class Calculator extends React.Component {
	constructor(props) {
		super(props);

		this.state = { length: 1, edit: true };
	}

	componentDidMount() {
		const { investorInfo } = this.props;
		if (investorInfo) {
			this.setState({ ...investorInfo });
		}
	}

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	onChangePhoneNumber = (value) => {
		this.setState({ mobile_number: value });
	};

	onClickCalculate = () => {
		let stateData = { ...this.state };
		let data = {
			firstname: stateData.firstname,
			lastname: stateData.lastname,
			length: stateData.length,
			email: stateData.email,
			mobile_number: stateData.mobile_number,
			starting_funds: stateData.starting_funds,
			currency: stateData.currency,
		};

		if (
			isEmptyOrNull(data.firstname) ||
			isEmptyOrNull(data.lastname) ||
			isEmptyOrNull(data.length) ||
			isEmptyOrNull(data.email) ||
			isEmptyOrNull(data.mobile_number) ||
			isEmptyOrNull(data.starting_funds) ||
			isEmptyOrNull(data.currency)
		) {
			swal("", "All fields are required", "info");
		} else {
			data.starting_funds = Number(data.starting_funds);
			data.length = Number(data.length);
			if (data.starting_funds >= 1000) {
				this.props.loaderState(true);
				this.props.saveData(data);
				setTimeout(() => {
					this.setState({ edit: false });
				}, 500);
			} else {
				swal("", "Amount should not be less than 1000", "info");
			}
		}
	};

	onChangeStep = (month) => {
		this.setState({ length: Number(month) });
	};

	onChangeSliderValue = (o, v) => {
		this.setState({ length: Number(v) });
	};

	getMonths = (length) => {
		let months = [];
		var i;
		let yearChange = false;
		let year = moment().format("YYYY");

		for (i = 0; i < Number(length); i++) {
			let month = moment().add(i, "months");

			let monthTo = moment(month).format("MMMM");
			let previousMonth = moment().add(i - 1, "months");

			if (moment(previousMonth).format("MMMM") === "December") {
				yearChange = true;
				year = moment(moment().add(1, "years")).format("YYYY");
			}

			months.push(monthTo + " " + year);
		}
		return months;
	};

	perpareLabels = (length) => {
		let months = this.getMonths(length);
		let newData = ["Starting Funds"];
		_.forEach(months, (month) => {
			newData.push(month);
			// newData.push("");
		});

		return newData;
	};

	onClickEdit = () => {
		this.setState({ edit: true });
	};

	render() {
		const stateData = { ...this.state };
		const { prediction, investor_info, language } = this.props;
		let startingFunds = stateData && stateData.starting_funds ? stateData.starting_funds : 0;

		const chartData = {
			labels: this.perpareLabels(prediction ? prediction.investment_length : 0),
			datasets: [
				{
					fill: false,
					borderColor: "#1e75ff",
					pointBackgroundColor: "#1e75ff",
					pointBorderWidth: 7,
					pointHoverRadius: 7,
					pointHoverBackgroundColor: "#1e75ff",
					pointHoverBorderColor: "#1e75ff",
					pointHoverBorderWidth: 5,
					pointRadius: 1,
					data: prediction ? [Number(startingFunds)].concat(prediction.points) : [Number(startingFunds)],
				},
			],
		};
		trackPage(window.location.pathname + window.location.search);

		return (
			<CalculatorComp
				chartData={chartData}
				onChangeSliderValue={(o, v) => this.onChangeSliderValue(o, v)}
				onChange={(e) => this.onChange(e)}
				onClickCalculate={() => this.onClickCalculate()}
				onChangePhoneNumber={(e) => this.onChangePhoneNumber(e)}
				onClickEdit={() => this.onClickEdit()}
				stateData={stateData}
				language={language}
			/>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		prediction: state.calculator.prediction,
		investorInfo: state.calculator.investor_info,
		language: state.common.language,
	};
};

export default connect(mapStateToProps, { loaderState, saveData })(Calculator);
