import React from "react";
import TutorialsComp from "../../components/Tutorials";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class Tutorials extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		trackPage(window.location.pathname + window.location.search);

		return <TutorialsComp />;
	}
}

export default Tutorials;
