import React from "react";
import logo from "../../assets/images/DeepReef-Logo@2x.png";
import { Link } from "react-router-dom";

const Header = ({ url, onClickGoto, language, onChangeLang, languages }) => {
  let data = require(`../../languages/siteData_${language}.json`);
  return (
    <nav className="navbar navbar-default">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link
            to="/"
            className="navbar-brand"
            onClick={() => onClickGoto("/")}
          >
            <img src={logo} height="50" width="120" />
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li onClick={() => onClickGoto("/")}>
              <Link to="/" className={`link ${url === "/" ? "active" : ""}`}>
                {data.header_nav[0]}
              </Link>
            </li>
            <li onClick={() => onClickGoto("/services")}>
              <Link
                to="/services"
                className={`link ${url === "/services" ? "active" : ""}`}
              >
                {data.header_nav[1]}
              </Link>
            </li>
            {/* <li onClick={() => onClickGoto("/deepreef")}>
              <a className={`link ${url === "/deepreef" ? "active" : ""}`}>
                Deep Reef
              </a>
            </li> */}
            <li onClick={() => onClickGoto("/contactus")}>
              <Link
                to="/contactus"
                className={`link ${url === "/contactus" ? "active" : ""}`}
              >
                {data.header_nav[2]}
              </Link>
            </li>
            <li>
              <a
                className="link"
                href="https://user.deepreef.uk/"
                target="_blank"
              >
                {data.header_nav[3]}
              </a>
            </li>
            <li>
              <Link
                to="/investmentCalculator"
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: "#337ab7", color: "#fff" }}
                onClick={() => onClickGoto("/investmentCalculator")}
              >
                {data.investment_btn_text}
              </Link>
            </li>
            <li className="dropdown">
              <select
                className="form-control"
                id="language-select"
                onChange={(e) => onChangeLang(e)}
                value={language ? language : "ENGLISH"}
              >
                {languages.map((lang) => (
                  <option key={lang}>{lang}</option>
                ))}
              </select>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
