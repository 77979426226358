import React from "react";
import { connect } from "react-redux";
import DeepReefComp from "../../components/DeepReef";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class DeepReef extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		trackPage(window.location.pathname + window.location.search);

		return <DeepReefComp />;
	}
}

export default connect(null, {})(DeepReef);
