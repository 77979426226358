import history from "../history";

export const goto = (path) => {
  history.push(path);
  history.go(path);
};

export const isEmptyOrNull = (data) => {
  let valid = false;
  if (!data || data === "") {
    valid = true;
  }
  return valid;
};

export const removeNullValues = (object) => {
  let newObj = {};
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (!isEmptyOrNull(object[key])) {
        newObj[key] = object[key];
      }
    }
  }
  return newObj;
};
