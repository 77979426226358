import React from "react";
import ImagePlaceholder from "../../assets/images/4@2x.png";

const InvestmentPlan = ({ onClickGoto, data, btn_name }) => {
  return (
    <div className="row investment-plan" id="sub-comp">
      <div className="col-md-6" id="left-image">
        <img src={ImagePlaceholder} className="sub-comp-img" />
      </div>
      <div className="col-md-6">
        <label style={{ color: "#337ab7" }}>DEEP REEF</label>
        <h1>{data.heading.toUpperCase()}</h1>
        <br />
        <p>{data.plans}</p>
        <br />
        <button
          className="btn btn-primary"
          style={{ marginRight: "15px" }}
          onClick={() => onClickGoto("/investmentCalculator")}
        >
          {btn_name}
        </button>
        {/* <div id="custom-ul">
          {data.plans.map((plan, i) => (
            <div id="custom-li" key={i}>
              <div id="custom-li-icon">
                <i className="fa fa-check" />
              </div>
              <div id="custom-li-text">{plan}</div>
            </div>
          ))}
          <div id="custom-li">
            <div></div>
            <div>
              <button
                className="btn btn-primary"
                style={{ marginRight: "15px" }}
                onClick={() => onClickGoto("/investmentCalculator")}
              >
                {btn_name}
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div className="col-md-6" id="right-image">
        <img src={ImagePlaceholder} className="sub-comp-img" />
      </div>
    </div>
  );
};

export default InvestmentPlan;
