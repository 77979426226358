import React from "react";

const ContactForm = ({ onChange, onSendFeedback, data }) => {
	let inputData = data.inputs;
	return (
		<div className="contact-from">
			<h1>{data.heading.toUpperCase()}</h1>
			<span style={{ width: "" }}>{data.description}</span>
			<div className="form-group" style={{ marginTop: "40px" }}>
				<label htmlFor="name">{inputData[0].label}</label>
				<input type="text" onChange={(e) => onChange(e)} placeholder={inputData[0].placeholder} className="form-control" id="name" />
			</div>
			<div className="form-group">
				<label htmlFor="email">{inputData[1].label}</label>
				<input type="email" onChange={(e) => onChange(e)} placeholder={inputData[1].placeholder} className="form-control" id="email" />
			</div>
			<div className="form-group">
				<label htmlFor="subject">{inputData[2].label}</label>
				<input type="text" onChange={(e) => onChange(e)} placeholder={inputData[2].placeholder} className="form-control" id="subject" />
			</div>
			<div className="form-group">
				<label htmlFor="message">{inputData[3].label}</label>
				<textarea
					type="text"
					onChange={(e) => onChange(e)}
					placeholder={inputData[3].placeholder}
					className="form-control"
					id="message"
					rows={10}
					cols={5}
				/>
			</div>
			<div className="form-group">
				<button className="btn btn-primary" style={{ padding: "10px 40px", margin: "10dp" }} onClick={() => onSendFeedback()}>
					{data.btn_name}
				</button>
				{/* <button
					href="https://user.deepreef.uk/"
					className="btn btn-primary"
					style={{ padding: "10px 40px", margin: "10dp" }}
					onClick={() => "https://user.deepreef.uk/"}
				>
					{data.insta_name}
				</button> */}
			</div>
		</div>
	);
};

export default ContactForm;
