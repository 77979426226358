import React from "react";
import Card from "./Card";
import _ from "lodash";

const OurMission = ({ data }) => {
  let cardsData = [];
  _.forEach(data.features, (feature, index) => {
    cardsData.push({
      title: feature.heading,
      description: feature.description,
      iconName: `icon${index + 1}@2x`,
    });
  });

  return (
    <div id="sub-comp">
      <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-8" style={{ textAlign: "center" }}>
          <h1>{data.heading.toUpperCase()}</h1>
          <p>{data.description}</p>
        </div>
        <div className="col-sm-2"></div>
      </div>
      <div className="row" style={{ marginTop: "20px" }} id="mission-cards">
        {cardsData.map((data) => {
          var icon = require(`../../assets/images/${data.iconName}.png`);
          return (
            <Card
              title={data.title.toUpperCase()}
              key={data.title}
              description={data.description}
              icon={icon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OurMission;
