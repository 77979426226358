import { calculator } from "../../constants/actionTypes";

const initialState = {
  investor_info: null,
  prediction: null,
};

function calculatorReducer(state = initialState, action) {
  switch (action.type) {
    case calculator.INVESTOR_INFO:
      return { ...state, investor_info: action.payload };
    case calculator.CALCULATOR_RESULT:
      return { ...state, prediction: action.payload };
    default:
      return state;
  }
}
export default calculatorReducer;
