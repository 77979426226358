import React from "react";

const GetStarted = ({ onClickGoto, data, btn_name }) => {
  return (
    <div id="get-started" style={{ backgroundColor: "#1E75FF" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div style={{ fontSize: "3.5em" }}>{data.heading}</div>
            <div style={{ fontSize: "1.2em" }}>{data.description}</div>
          </div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <button
              className="btn"
              id="cal-invest-btn-lg"
              onClick={() => onClickGoto("/investmentCalculator")}
            >
              {btn_name}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
