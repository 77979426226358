import React from "react";

const DeepReef = () => {
  return (
    <div className="container" id="deepreef">
      DeepReef
    </div>
  );
};

export default DeepReef;
