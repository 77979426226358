import React from "react";
import ImagePlaceholder from "../../assets/images/1@2x.png";
import DRVideo from "../../assets/videos/deepreef.mp4";

const Overview = ({ onClickGoto, data, btn_name }) => {
	return (
		<div className="row overview" id="sub-comp">
			<div className="col-md-6">
				<label style={{ color: "#337ab7" }}>DEEP REEF</label>
				<div className="heading">{data.heading.toUpperCase()}</div>
				<p>{data.description}</p>
				<div style={{ marginTop: "40px" }}>
					<button className="btn btn-primary" style={{ marginRight: "15px" }} onClick={() => onClickGoto("/investmentCalculator")}>
						{btn_name}
					</button>
					{/* <button className="btn btn-default">
            <i className="fa fa-play" style={{ marginRight: "10px" }} />
            Watch the demo video
          </button> */}
				</div>
			</div>
			<div className="col-md-6">
				{/* <img src={ImagePlaceholder} className="sub-comp-img" /> */}
				{/* <video className="sub-comp-img" controls>
					<source src="https://drive.google.com/file/d/1_tUpQIIHMhrL5fIKmerCEd1Ck9AB0l__/view?usp=sharing" type="video/mp4" />
				</video> */}
				{/* <ReactPlayer src={DRVideo} /> */}

				<video className="sub-comp-img" controls src="https://deepreef-user-picture.s3.eu-west-1.amazonaws.com/deepreef+(1).mp4">
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
};

export default Overview;
