import React from "react";
import { selectedNav, loaderState, sendFeedBack } from "../../actions";
import { connect } from "react-redux";
import { goto } from "../../helper";
import ContactUsComp from "../../components/ContactUs";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class ContactUs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onClickGoto = (path) => {
		this.props.selectedNav(path);
		goto(path);
	};

	onChange = ({ target }) => {
		const { id, value } = target;
		this.setState({ [id]: value });
	};

	onSendFeedback = () => {
		this.props.loaderState(true);
		let data = { ...this.state };
		this.props.sendFeedBack(data);
	};

	render() {
		const { language } = this.props;
		trackPage(window.location.pathname + window.location.search);

		return (
			<ContactUsComp
				onClickGoto={(path) => this.onClickGoto(path)}
				onSendFeedback={() => this.onSendFeedback()}
				onChange={(e) => this.onChange(e)}
				language={language}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.common.language,
	};
};

export default connect(mapStateToProps, {
	selectedNav,
	loaderState,
	sendFeedBack,
})(ContactUs);
