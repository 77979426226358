import React from "react";

const Working = () => {
  return (
    <div className="container" id="working">
      How it works
    </div>
  );
};

export default Working;
