import React from "react";
import ImagePlaceholder from "../../assets/images/3@2x.png";

const MemberShip = ({ onClickGoto, data, btn_name }) => {
  return (
    <div className="row membership" id="sub-comp">
      <div className="col-md-6">
        <label style={{ color: "#337ab7" }}>DEEP REEF</label>
        <h1>{data.heading.toUpperCase()}</h1>
        <div id="custom-ul">
          {data.benefits.map((benefit) => (
            <div id="custom-li" key={benefit}>
              <div id="custom-li-icon">
                <i className="fa fa-check" />
              </div>
              <div id="custom-li-text">{benefit}</div>
            </div>
          ))}
          <div id="custom-li">
            <div></div>
            <div>
              <button
                className="btn btn-primary"
                style={{ marginRight: "15px" }}
                onClick={() => onClickGoto("/investmentCalculator")}
              >
                {btn_name}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <img src={ImagePlaceholder} className="sub-comp-img" />
      </div>
    </div>
  );
};

export default MemberShip;
