import React from "react";
import Card from "../common/Card";
import ContactForm from "./ContactForm";
import Map from "./Map";
import GetStarted from "../common/GetStarted";
import _ from "lodash";

const ContactUs = ({
	// contacts,
	onClickGoto,
	onSendFeedback,
	onChange,
	language,
}) => {
	let data = require(`../../languages/siteData_${language}.json`);
	let contactUs = data.contact_us;

	let contacts = [];
	_.forEach(contactUs.contacts, (contact, index) =>
		contacts.push({
			icon: `icon${index + 10}@2x`,
			title: contact.name,
			description: contact.description,
			moreInfo: contact.value,
		}),
	);
	return (
		<>
			<div className="container" id="contactus">
				<div className="comp-info">
					<label className="comp-title">{contactUs.heading.toUpperCase()}</label>
					<h3>{contactUs.sub_heading.toUpperCase()}</h3>
					<div className="comp-desc">
						<div className="row">
							<div className="col-sm-3"></div>
							<div className="col-sm-6">{contactUs.description}</div>
						</div>
					</div>
				</div>
				<div className="row" style={{ marginTop: "50px" }}>
					{contacts
						? contacts.map((contact) => {
								var icon = require(`../../assets/images/${contact.icon}.png`);
								return (
									<div className="col-sm-4" key={contact.title}>
										<Card
											icon={icon}
											title={contact.title.toUpperCase()}
											description={contact.description}
											moreInfo={contact.moreInfo}
										/>
									</div>
								);
						  })
						: null}
				</div>
				<div className="row" style={{ marginTop: "40px" }}>
					<div className="col-md-6">
						<ContactForm onSendFeedback={() => onSendFeedback()} onChange={(e) => onChange(e)} data={contactUs.feedback} />
					</div>
					<div className="col-md-6">
						<Map
							googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `650px` }} />}
							mapElement={<div style={{ height: `100%`, borderRadius: "10px" }} />}
						/>
					</div>
				</div>
			</div>
			<GetStarted onClickGoto={(path) => onClickGoto(path)} data={data.getting_started} btn_name={data.investment_btn_text} />
		</>
	);
};

export default ContactUs;
