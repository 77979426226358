import React from "react";
import { APP_VERSION } from "../../constants/global-constant";

const Footer = ({ onClickGoto, onChange, onSaveEmail, language }) => {
	let data = require(`../../languages/siteData_${language}.json`);
	let questions = data.footer.questions;
	let quick_links = data.footer.quick_links;
	let help = data.footer.help;
	let newsletter = data.footer.newsletter;
	return (
		<div id="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-4 col-sm-6">
						<h4>{questions.heading}</h4>
						<ul>
							<li>
								<i className="fa fa-phone" />
								{questions.data[0].heading}
								<div>{questions.data[0].text}</div>
							</li>
							<li>
								<i className="fa fa-map-marker" />
								{questions.data[1].heading}
								<div>{questions.data[1].text}</div>
							</li>
							<li>
								<i className="fa fa-envelope" />
								{questions.data[2].heading}
								<div>{questions.data[2].text}</div>
							</li>
						</ul>
					</div>
					<div className="col-md-4 col-xs-6">
						<h4>{quick_links.heading}</h4>
						<ul>
							<li className="link" onClick={() => onClickGoto("/")}>
								{quick_links.links[0]}
							</li>
							<li className="link" onClick={() => onClickGoto("/services")}>
								{quick_links.links[1]}
							</li>
							<li className="link" onClick={() => onClickGoto("/contactus")}>
								{quick_links.links[2]}
							</li>
							<li className="link" onClick={() => onClickGoto("/investmentCalculator")}>
								{quick_links.links[3]}
							</li>
							<li>
								<a
									className="link"
									style={{ color: "#ffffff" }}
									href="https://www.instagram.com/deep_reef_investment/"
									target="_blank"
								>
									{quick_links.links[4]}
								</a>
							</li>
						</ul>
					</div>
					{/* <div className="col-md-3 col-xs-6">
						<h4>{help.heading}</h4>
						<ul>
							<li className="link" onClick={() => onClickGoto("/terms")}>
								{help.links[0]}
							</li>
							<li className="link" onClick={() => onClickGoto("/privacy policy")}>
								{help.links[1]}
							</li>
							<li className="link" onClick={() => onClickGoto("/faq")}>
								{help.links[2]}
							</li>
							<li className="link" onClick={() => onClickGoto("/tutorials")}>
								{help.links[3]}
							</li>
							<li className="link" onClick={() => onClickGoto("/working")}>
								{help.links[4]}
							</li>
						</ul>
					</div> */}
					<div className="col-md-4 col-sm-6">
						<h4>{newsletter.heading}</h4>
						<ul>
							<li>{newsletter.text}</li>
						</ul>
						<div className="input-group">
							<input
								id="email"
								type="email"
								className="form-control"
								placeholder={newsletter.input_placeholder}
								onChange={(e) => onChange(e)}
							/>
							<div className="input-group-btn">
								<button className="btn btn-primary" onClick={() => onSaveEmail()}>
									<i className="fa fa-paper-plane"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-lg-12" id="copy-right">
						<i className="fa fa-copyright" /> {data.copy_rights} - {APP_VERSION}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
