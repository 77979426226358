import React from "react";
import { Route, Switch } from "react-router-dom";

//containers
import Home from "../containers/Home";
import ContactUs from "../containers/ContactUs";
import Calculator from "../containers/Calculator";
import Services from "../containers/Services";
import DeepReef from "../containers/DeepReef";
import AboutUs from "../containers/AboutUs";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import FAQ from "../containers/FAQ";
import TermsAndConditions from "../containers/TermsAndConditions";
import Tutorials from "../containers/Tutorials";
import Working from "../containers/Working";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/investmentCalculator" component={Calculator} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/deepreef" component={DeepReef} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/privacy policy" component={PrivacyPolicy} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/terms" component={TermsAndConditions} />
      <Route exact path="/tutorials" component={Tutorials} />
      <Route exact path="/working" component={Working} />
    </Switch>
  );
};

export default Routes;
