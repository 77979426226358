import React from "react";

const Card = ({ icon, title, description, moreInfo }) => {
  return (
    <div id="card">
      <img src={icon} width="60px" height="60px" />
      {/* <i id="card-icon" className={icon} /> */}
      <h2>{title}</h2>
      <p style={{ fontSize: "1em" }}>{description}</p>
      <label>{moreInfo}</label>
    </div>
  );
};

export default Card;
