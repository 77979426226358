import React from "react";

const Terms = () => {
  return (
    <div className="container" id="terms">
      Terms and Conditions
    </div>
  );
};

export default Terms;
