import React from "react";
import { selectedNav } from "../../actions";
import { connect } from "react-redux";
import { goto } from "../../helper";
import HomeComp from "../../components/Home";
import ReactGA from "react-ga";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickGoto = (path) => {
    this.props.selectedNav(path);
    goto(path);
  };

  render() {
    const { language } = this.props;
    trackPage(window.location.pathname + window.location.search);

    return (
      <HomeComp
        onClickGoto={(path) => this.onClickGoto(path)}
        language={language}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.common.url,
    language: state.common.language,
  };
};

export default connect(mapStateToProps, { selectedNav })(Home);
