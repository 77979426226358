import React from "react";
import { selectedNav, sendEmail, loaderState } from "../../actions";
import { connect } from "react-redux";
import { goto } from "../../helper";
import FooterComp from "../../components/common/Footer";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickGoto = (path) => {
    this.props.selectedNav(path);
    goto(path);
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onSaveEmail = () => {
    const { email } = this.state;
    this.props.loaderState(true);
    this.props.sendEmail({ email });
  };

  render() {
    const { language } = this.props;

    return (
      <FooterComp
        onClickGoto={(path) => this.onClickGoto(path)}
        onSaveEmail={() => this.onSaveEmail()}
        onChange={(e) => this.onChange(e)}
        language={language}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.common.language,
  };
};

export default connect(mapStateToProps, {
  selectedNav,
  sendEmail,
  loaderState,
})(Footer);
