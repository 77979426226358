import React from "react";
import { selectedNav } from "../../actions";
import { connect } from "react-redux";
import { goto } from "../../helper";
import ServicesComp from "../../components/Services";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class Services extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onClickGoto = (path) => {
		trackPage(window.location.pathname + window.location.search);

		this.props.selectedNav(path);
		goto(path);
	};

	render() {
		const { language } = this.props;
		return <ServicesComp onClickGoto={(path) => this.onClickGoto(path)} language={language} />;
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.common.language,
	};
};

export default connect(mapStateToProps, { selectedNav })(Services);
