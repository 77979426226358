import React from "react";
import ImagePlaceholder from "../../assets/images/2@2x.png";

const Advice = ({ onClickGoto, data, btn_name }) => {
  return (
    <div className="row advice" id="sub-comp">
      <div className="col-md-6" id="left-image">
        <img src={ImagePlaceholder} className="sub-comp-img" />
      </div>
      <div className="col-md-6">
        <label style={{ color: "#337ab7" }}>DEEP REEF</label>
        <h1>{data.heading.toUpperCase()}</h1>
        <div id="custom-ul">
          {data.advices.map((advice) => (
            <div id="custom-li" key={advice}>
              <div id="custom-li-icon">
                <i className="fa fa-check" />
              </div>
              <div id="custom-li-text">{advice}</div>
            </div>
          ))}

          <div id="custom-li">
            <div></div>
            <div>
              <button
                className="btn btn-primary"
                style={{ marginRight: "15px" }}
                onClick={() => onClickGoto("/investmentCalculator")}
              >
                {btn_name}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6" id="right-image">
        <img src={ImagePlaceholder} className="sub-comp-img" />
      </div>
    </div>
  );
};

export default Advice;
