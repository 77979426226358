import React from "react";
import { selectedNav, changeLanguage } from "../../actions";
import { connect } from "react-redux";
import { goto } from "../../helper";
import HeaderComp from "../../components/common/Header";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickGoto = (path) => {
    this.props.selectedNav(path);
    // goto(path);
  };

  onChangeLang = ({ target }) => {
    this.props.changeLanguage(target.value);
  };

  render() {
    const { url, language } = this.props;
    let languages = [
      "ENGLISH",
      "FRENCH",
      "RUSSIAN",
      "TURKISH",
      "ARABIC",
      "LITHUANIAN",
    ];

    return (
      <HeaderComp
        url={url}
        onClickGoto={(path) => this.onClickGoto(path)}
        language={language}
        onChangeLang={(e) => this.onChangeLang(e)}
        languages={languages}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.common.url,
    language: state.common.language,
  };
};

export default connect(mapStateToProps, { selectedNav, changeLanguage })(
  Header
);
