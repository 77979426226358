import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: 37.2707, lng: -76.70746 }}>
      <Marker position={{ lat: 37.2707, lng: -76.70746 }} />
    </GoogleMap>
  ))
);

export default Map;
