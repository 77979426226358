import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Slider from "./Slider";

const Calculate = ({
  stateData,
  onChange,
  onChangePhoneNumber,
  onChangeSliderValue,
  onClickCalculate,
  data,
  btn_name,
}) => {
  return (
    <>
      <div className="comp-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstname">{data[0].label}</label>
              <input
                type="text"
                onChange={(e) => onChange(e)}
                placeholder={data[0].placeholder}
                className="form-control"
                id="firstname"
                value={
                  stateData && stateData.firstname != null
                    ? stateData.firstname
                    : ""
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastname">{data[1].label}</label>
              <input
                type="text"
                onChange={(e) => onChange(e)}
                placeholder={data[1].placeholder}
                className="form-control"
                id="lastname"
                value={
                  stateData && stateData.lastname != null
                    ? stateData.lastname
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">{data[2].label}</label>
              <input
                type="text"
                onChange={(e) => onChange(e)}
                placeholder={data[2].placeholder}
                className="form-control"
                id="email"
                value={
                  stateData && stateData.email != null ? stateData.email : ""
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="mobile_number">{data[3].label}</label>
              <PhoneInput
                country={"gb"}
                // onlyCountries={["gb", "us", "ca"]}
                placeholder={data[3].placeholder}
                value={stateData ? stateData.mobile_number : ""}
                onChange={(phone) => onChangePhoneNumber(phone)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="starting_funds">{data[4].label}</label>
              <input
                type="text"
                onChange={(e) => onChange(e)}
                placeholder={data[4].placeholder}
                className="form-control"
                id="starting_funds"
                value={
                  stateData && stateData.starting_funds != null
                    ? stateData.starting_funds
                    : ""
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="currency">{data[5].label}</label>
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                id="currency"
                value={
                  stateData && stateData.currency != null
                    ? stateData.currency
                    : ""
                }
              >
                <option value="">{data[5].placeholder}</option>
                <option value="GBP">GBP</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="USD">AED</option>
                <option value="USD">KZT</option>
                <option value="USD">RUB</option>
                <option value="USD">TURKISH LIRA</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="months">{data[6].label}</label>
            <Slider
              onChange={(o, v) => onChangeSliderValue(o, v)}
              value={stateData && stateData.length ? stateData.length : 1}
            />
          </div>
        </div>
      </div>
      <div className="pridiction-card">
        *One investment round lasts 30 Days. Then we have 10 day grace period.
        After that next investment round starts
      </div>
      <div className="card-footer" style={{ marginTop: "40px" }}>
        <button className="btn btn-primary" onClick={() => onClickCalculate()}>
          {btn_name}
        </button>
      </div>
    </>
  );
};

export default Calculate;
