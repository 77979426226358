import React from "react";
import AboutUsComp from "../../components/AboutUs";
import ReactGA from "react-ga";

const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

class AboutUs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		trackPage(window.location.pathname + window.location.search);

		return <AboutUsComp />;
	}
}

export default AboutUs;
